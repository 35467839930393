<template>
  <div class="welfareSetMealDetails">
    <search-com @search-btn="searchBtn"></search-com>
    <div class="nav_box">
      <welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
    </div>
    <div class="goodDetails_box">
      <div class="good_img">
        <img v-if="mealDetails.mealUrl" :src="mealDetails.mealUrl" />
      </div>
      <div class="good_info">
          <div class="info_header">{{mealDetails.mealName}}</div>
          <div class="info_price">
              价格：<span class="red">¥<span class="fz22">{{mealDetails.mealPrice}}</span></span>
          </div>
          <div class="tag">
              <span class="tag_info">{{mealDetails.festivalName}}</span>
          </div>
          <div class="buy_num">
              购买数量：
              <el-input-number size="small" v-model.number="num" :min="1" @change="handleChange"  @input.native="preventPoint"></el-input-number>
          </div>
          <div class="cart">
            <div>
              <el-button style="color:#fff;" v-if="mealIsActive" type="danger" @click="addCarBtn">
                  <i class="el-icon-shopping-cart-1"></i>
                  加入购物车
              </el-button>
              <el-button v-else type="info" plain disabled>
                  礼包已下架
              </el-button>
            </div>
            <span class="buy_tips">*加入购物车，去购物车下单</span>
          </div>
      </div>
    </div>

    <!-- 员工兑换一下任意商品 -->
    <div class="conversion_box">
      <div style="display: flex;margin-bottom: 24px;">
        <h1>员工可以兑换以下任一商品：</h1>
        <el-link href="https://www.1renshi.com/News/60182.html" target="_blank"  type="primary" style="font-size: 16px;">（员工兑换福利流程说明）</el-link>
      </div>
      <good-list-com
      :list="goodList"
      @handle-current-change="handleCurrentChange"
      @handle-size-change="handleSizeChange"></good-list-com>
    </div>
  </div>
</template>

<script>
import WelfareBreadcrumb from '@/components/welfareMallComp/WelfareBreadcrumb';
import SearchCom from '@/components/welfareMallComp/SearchCom';
import GoodListCom from '@/components/welfareMallComp/GoodListCom.vue';
import { getReqMealDetails, getReqLoginAddCart } from "@/network/welfareMall_api";
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'WelfareSetMealDetails',
  components: {
    WelfareBreadcrumb,
    GoodListCom,
    SearchCom
  },
  data() {
    return {
      itemBreadcrumb: [
        {
          name: '福利商城',
          to: '/welfareHome'
        },
        {
          name: '员工N选1礼包',
          to: '/welfareSetMealList'
        },
        {
          name: '礼包详情'
        },
      ],
      pagination: {
        pageIndex: 1,
        limit: 10,
        total: 100
      },
      goodList: [],
      num: '',
      mealPrice: '',
      festivalName: '',
      mealName: '',
      mealDetails: {},//礼包信息

      mealIsActive: true,//礼包是否有效 默认有效

    };
  },
  computed:{
    // 映射
    ...mapState(['useInfo'])
  },
  watch:{
  },
  created() {
     // 默认从工作台跳转的都是有效的
    if(this.$route.query.id){
      this.mealIsActive = true;
      this.getMealDetails(this.$route.query.id);
    }
    // 判断从router来吗？
    if(this.$route.params.mealID){
      this.getMealDetails(this.$route.params.mealID);
    }else if(sessionStorage.getItem('welfareMall_mealId')){
      // 页面点刷新走这一步
      this.getMealDetails(sessionStorage.getItem('welfareMall_mealId'));
    }
    // 判断当前礼包是否有效
    if(sessionStorage.getItem('welfareMall_isActive')){
      this.mealIsActive = JSON.parse(sessionStorage.getItem('welfareMall_isActive'));
    }
    setTimeout(()=>{
      document.title = '[壹人事]' + this.mealDetails.mealName;
    },1000)
  },
  
  methods: {
    // 映射mapMutations里的方法
    ...mapMutations(['saveCartNum']),
    // 点击查询 search
    searchBtn (val) {
      if(!val){
        return this.$message.info('请输入商品或礼包名称');
      }
      document.cookie = "vueRouter="+ '/welfareSearchResult';
      this.$router.push({
        name: 'WelfareSearchResult',
        params: {
          value: val
        }
      })
    },
    handleChange(){},
    // 计数器 只输入整数
    preventPoint(e) {
        this.$message.closeAll();
        // 验证是否是纯数字
        let isNumber = /^\d*$/.test(e.target.value);
        // 过滤非数字
        e.target.value = e.target.value.replace(/\D/g, "");
        if (!isNumber || e.target.value < 0) {
          layer.msg("只能输入正整数");
        }
        e.target.value = (e.target.value >= 0 && e.target.value.match(/^\d*/g)[0]) || 1;
    },
    async addCarBtn(){
      var myCartList = [];
      var cartObj = this.mealDetails;
      cartObj.mealCount = this.num;
      cartObj.isActive = true;// 初始化当前礼包显示为有效
      cartObj.amount = cartObj.mealPrice * this.num;//金额
      cartObj.orderType = 1;//类别
      // 判断是否已在缓存中添加有购物车
      if(sessionStorage.getItem('myCartList') && !this.useInfo.privateUserID){
        myCartList = JSON.parse(sessionStorage.getItem('myCartList'));
        // 此时是未登录状态添加进缓存
        // 判断是否有该礼包  无 则push缓存 反之
        var boo = myCartList.some((item)=>{
          return item.mealID === cartObj.mealID;
        })
        if(!boo){
          myCartList.push(cartObj);
        }else{
          // 循环判断缓存中是否有改同样礼包
          myCartList.forEach((item)=>{
            // 如果有 数量相加
            if(item.mealID === cartObj.mealID){
              item.mealCount += cartObj.mealCount;
              item.amount = (item.mealCount * item.mealPrice);
            }
          })
        }
        sessionStorage.setItem('myCartList', JSON.stringify(myCartList));
        this.saveCartNum(myCartList.length);
        this.$message.success('加入购物车成功');
        // 判断是否是登录状态
      }else if(!this.useInfo.privateUserID){
        myCartList.push(cartObj);
        sessionStorage.setItem('myCartList', JSON.stringify(myCartList));
        this.saveCartNum(myCartList.length);
        this.$message.success('加入购物车成功');
        // 已登录状态
      }else if(this.useInfo.privateUserID){
        const {mealID, mealCount} = cartObj;
        // 加入购物车调登录的加入接口
        var res = await getReqLoginAddCart({
          mealID,
          mealCount
        });
        if(res.isSuccess){
          this.$message.success(res.msg);
          // 在vuex中调用获取当前登录用户的购物车数量
          this.$store.dispatch('getLoginCartNum');
        }else{
          this.$message.error(res.msg);
        }
      }
    },
    // 获取礼包详情
    async getMealDetails (id){
      var res = await getReqMealDetails(id);
      if(res.isSuccess){
        this.mealDetails = res.data;
        var goodList = res.data.mealCommodityListDtos;
        // 过滤商品 配置组件所需对应的 property
        goodList.forEach(function (item) {
          item.mealUrl = item.commodityPictures.split(';')[0];
          item.mealName = item.goodName?item.goodName : item.commodityName;
          // 一下两个 property 是GoodlistCom组件里点击跳转所用到
          item.type = 1;
          item.mealID = item.commodityID;
        })
        this.goodList = goodList;
      }else{
        this.$message.error(res.msg);
      }

    },
    // 页面条数发生改变
    handleSizeChange(val){
      
    },
    //页面页码发生改变 
    handleCurrentChange(val){
      
    }
  },
  destroyed() {

    // 清除cookie welfareMall_goodId
    // document.cookie = "username=welfareMall_goodId; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  },
};
</script>

<style scoped lang="less">
.welfareSetMealDetails{
  // 商品信息
  .goodDetails_box{
    width: 1200px;
    margin: 0 auto;
    margin-top: 24px;
    display: flex;
    .good_img{
        width: 387px;
        height: 387px;
      img{
        width: 387px;
        height: 387px;
      }
    }
    // 商品info
    .good_info{
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .info_header{
          width: 796px;
          font-size: 16px;
          color: #333333;
          overflow:hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
      }
      .info_price{
          font-size: 16px;
          padding: 24px 0;
          .red{
              color: #F5222D;
              .fz22{
                  font-size: 22px;
                  font-weight: bold;
                  margin-left: 5px;
              }
          }
      }
      .buy_num{
        
      }
      .tag{
          padding: 25px 0;
          .tag_info{
            font-size: 14px;
              width: 96px;
              border: 1px solid #BAE7FF;
              border-radius: 4px;
              background: #BAE7FF;
              color: #1890FF;
              padding: 8px;
          }
      }
      .cart{
        display: flex;
        flex-direction: column;
        .buy_tips{
          color:#999999;
          font-size: 14px;
        }
      }
      
    }
  }
  // 员工可兑换。。。
  .conversion_box{
    width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
    h1{
      
      font-size: 18px;
    }
  }
}
</style>
